<template>
	<div>
		<main class="container">
			<div class="flex space-between mb-40">
				<div>
					<h1 class="mb-10">{{ terminal.verifoneSerialnumber }}</h1>

					<p class="color-grey">Upplýsingar um terminal, {{ terminal.verifoneSerialnumber }}</p>
				</div>

				<div class="flex align-center">
					<div class="mr-10">
						<router-link class="btn-round far fa-edit" :to="{ name: 'TerminalEdit', params: { id: terminal.id || -1 } }"></router-link>
					</div>

					<Mail :terminal="terminal" :agreement="agreement" :customer="customer" type="Terminali" />
				</div>
			</div>

			<div class="color-grey grid grid-2 grid-gap-80 mb-40">
				<div>
					<div class="flex space-between mb-10">
						<div>Leiguterminalur</div>

						<div class="text-right">
							<strong>{{ terminal.lease ? 'Ja' : 'Nei' }}</strong>
						</div>
					</div>

					<div class="flex space-between mb-10">
						<div>Verifone SN</div>

						<div class="text-right">
							<strong>{{ terminal.verifoneSerialnumber }}</strong>
						</div>
					</div>

					<div class="flex space-between mb-10">
						<div>Verifone NR</div>

						<div class="text-right">
							<strong>{{ terminal.verifoneNumber }}</strong>
						</div>
					</div>

					<div class="flex space-between mb-10">
						<div>Telefonnr.</div>

						<div class="text-right">
							<strong>{{ terminal.phoneNumber }}</strong>
						</div>
					</div>
				</div>

				<div>
					<div class="flex space-between mb-10">
						<div>ICC</div>

						<div class="text-right">
							<strong>{{ terminal.icc }}</strong>
						</div>
					</div>

					<div class="flex space-between mb-10">
						<div>PSAM</div>

						<div class="text-right">
							<strong>{{ terminal.psam }}</strong>
						</div>
					</div>

					<div class="flex space-between mb-10">
						<div>Kundi</div>

						<div class="text-right" v-if="customer">
							<router-link :to="{ name: 'CustomerShow', params: { id: customer.id } }" class="heavy hover:color-blue">
								{{ customer.name }}
							</router-link>
						</div>

						<div v-if="!customer">-</div>
					</div>

					<div v-if="agreement" class="flex space-between mb-10">
						<div>Avtala</div>

						<div class="text-right">
							<router-link :to="{ name: 'AgreementShow', params: { id: agreement.id } }" class="heavy hover:color-blue">
								Far til avtalu <i class="fas fa-arrow-right ml-5"></i>
							</router-link>
						</div>
					</div>
				</div>
			</div>

			<div class="mb-30" v-if="terminal.comments">
				<h2 class="block mb-10">Viðmerking</h2>

				<div class="color-grey">{{ terminal.comments }}</div>
			</div>

			<div class="mb-30">
				<div class="mb-10">
					<label class="inline-flex align-center cursor-pointer">
						<div class="switch" :class="{ active: terminal.isSupport }">
							<input @change.prevent="toggleIsSupport" type="checkbox" v-model="terminal.isSupport" />

							<div></div>
						</div>

						<span class="ml-5">Support terminalur</span>
					</label>
				</div>

				<div class="mb-10">
					<label class="inline-flex align-center cursor-pointer">
						<div class="switch" :class="{ active: terminal.isTemporarilyOnAgreement }">
							<input @change.prevent="toggleIsTemporarilyOnAgreement" type="checkbox" v-model="terminal.isTemporarilyOnAgreement" />

							<div></div>
						</div>

						<span class="ml-5">Er fyribils á avtalu</span>
					</label>
				</div>

				<div>
					<label class="inline-flex align-center cursor-pointer">
						<div class="switch" :class="{ active: terminal.hasBeenReturned }">
							<input @change.prevent="toggleHasBeenReturned" type="checkbox" v-model="terminal.hasBeenReturned" />

							<div></div>
						</div>

						<span class="ml-5">Er latin inn aftur</span>
					</label>
				</div>
			</div>

			<div class="grid grid-2 grid-gap-40">
				<div>
					<h2 class="block mb-20">Loggur</h2>

					<div class="comments">
						<div v-if="terminal.logs && terminal.logs.length === 0" class="color-grey">Hesin terminalurin hevur ongar viðmerkingar.</div>

						<div v-for="(log, index) in terminal.logs" :key="`log-${index}`" class="comment">
							<div class="comment-text">{{ log.comments }}</div>

							<div class="comment-date">
								<span>{{ new Date(log.createdAt).format('%d. %mn %y kl. %H:%S') }}</span>
								|
								<span :class="{ 'color-blue': log.userName == '[System]' }">{{ log.userName }}</span>
							</div>
						</div>
					</div>
				</div>

				<div class="mt-15">
					<label class="block mb-10 color-grey-700" for="input-comment">Legg afturat</label>

					<textarea
						class="mb-20"
						id="input-comment"
						v-model="form.comments"
						placeholder="Skriva..."
						@blur="markAsDirty('comments')"
						@keydown.enter.prevent="saveLog()"
					></textarea>

					<div class="flex align-start">
						<a href="#" class="btn" :class="{ disabled: $v.form.comments.$invalid }" @click.prevent="saveLog()">Stovna</a>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import axios from 'axios';

import { required } from 'vuelidate/lib/validators';

import Mail from '../components/Mail.vue';

export default {
	name: 'TerminalShow',

	components: {
		Mail,
	},

	data() {
		return {
			terminal: {},

			agreement: null,

			customer: null,

			form: {
				comments: '',
			},
		};
	},

	mounted() {
		const terminalId = this.$route.params.id;

		axios
			.get(`/terminals/${terminalId}`)
			.then((response) => {
				this.terminal = response.data;

				const terminalAgreements = this.terminal.agreementTerminals;

				this.agreement = terminalAgreements[terminalAgreements.length - 1]?.agreement;

				if (this.agreement) {
					this.customer = this.agreement.customer;
				}
			})
			.catch(() => {
				console.error('Error in TerminalShow.vue when trying to fetch logs');
			});
	},

	validations: {
		form: {
			comments: {
				required,
			},
		},
	},

	methods: {
		hasError(inputField) {
			return this.$v.form[inputField].$invalid && this.$v.form[inputField].$dirty;
		},

		markAsDirty(inputField) {
			this.$v.form[inputField].$touch();
		},

		saveLog() {
			axios
				.post(`/terminals/${this.terminal.id}/log`, this.form)
				.then((response) => {
					this.form.comments = '';

					this.$v.form.$reset();

					this.terminal.logs = [response.data, ...this.terminal.logs];
				})
				.catch(() => {
					console.error('Error in TerminalShow.vue when trying to SAVE a log');
				});
		},

		deleteLog(logId) {
			axios
				.delete(`/terminals/${this.terminal.id}/log/${logId}`)
				.then(() => {
					this.terminal.logs = this.terminal.logs.filter((log) => log.id == logId);
				})
				.catch(() => {
					console.error('Error in TerminalShow.vue when trying to DELETE a log');
				});
		},

		toggleIsSupport() {
			axios
				.patch(`/terminals/${this.terminal.id}/toggle-is-support`, {
					isSupport: this.terminal.isSupport,
				})
				.catch(() => {
					console.error('Error trying to toggle support.');
				});
		},

		toggleIsTemporarilyOnAgreement() {
			axios
				.patch(`/terminals/${this.terminal.id}/toggle-is-temporarily-on-agreement`, {
					isTemporarilyOnAgreement: this.terminal.isTemporarilyOnAgreement,
				})
				.catch(() => {
					console.error('Error trying to toggle "is temporarily on agreement".');
				});
		},

		toggleHasBeenReturned() {
			axios
				.patch(`/terminals/${this.terminal.id}/toggle-has-been-returned`, {
					hasBeenReturned: this.terminal.hasBeenReturned,
				})
				.catch(() => {
					console.error('Error trying to toggle "has been returned".');
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.comments {
	display: flex;
	flex-direction: column;

	gap: 10px;
}

.comment {
	background: white;
	padding: 20px;

	border-radius: 10px;

	box-shadow: 0 2px 20px rgba(black, 0.05);

	.comment-text {
		margin-bottom: 8px;

		color: var(--color-grey-800);

		font-size: 15px;
		line-height: 1.5;
	}

	.comment-date {
		font-size: 13px;
		color: var(--color-grey-400);

		span {
			margin-left: 4px;
			margin-right: 4px;

			&:first-child {
				margin-left: 0;
			}
		}
	}
}
</style>

<style lang="scss" scoped>
#input-comment {
	resize: none;
}
</style>
